<template>
  <transition name="fade">
    <div class="modal gallery-modal" v-if="show" @click="closeModal">
      <light-box :media="media" @onClosed="closeModal"></light-box>
    </div>
  </transition>
</template>

<script>
import LightBox from "vue-image-lightbox";
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  name: "ModalGallery",
  components: {
    LightBox,
  },
  data() {
    return {
      show: false,
      media: [
        {
          thumb: "/img/gallery/6.png",
          src: "/img/gallery/6.png",
        },
        {
          thumb: "/img/gallery/1.jpg",
          src: "/img/gallery/1.jpg",
        },
        {
          thumb: "/img/gallery/2.jpg",
          src: "/img/gallery/2.jpg",
        },
        {
          thumb: "/img/gallery/3.jpg",
          src: "/img/gallery/3.jpg",
        },
        {
          thumb: "/img/gallery/4.jpg",
          src: "/img/gallery/4.jpg",
        },
        {
          thumb: "/img/gallery/5.jpg",
          src: "/img/gallery/5.jpg",
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
  },
};
</script>
