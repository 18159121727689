<template>
  <div class="d-inline-flex align-items-center justify-content-center" :class="bgColor">
      <span :class="{ 'active' : !checked }" >{{ labels[0] }}</span>
      <input type="checkbox" class="switch" 
        v-model="checked"
        >
      <span :class="{ 'active' : checked }" >{{ labels[1] }}</span>
  </div>
</template>
<script>
export default {
  name: "toggle",
  props:{
    labels: Array,
    bgColor: String,
    type: String,
  },
  data(){
    return{
      checked: false,
    }
  },
  watch:{
    checked(){
      this.$emit('toggle-checkbox', this.checked);
    }
  },
}
</script>
<style lang="scss">
div{
  &.orange{
    input[type="checkbox"].switch{
      background: var(--orange);
    }
    span{
      color: var(--gray);
      &.active{
        color: var(--orange);
      }
    } 
  }
  input[type="checkbox"].switch{
    appearance: none;
    width: 3.5rem;
    height: 2rem;
    background: var(--black);
    margin: 0 0.5rem;
    border-radius: 3rem;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all .2s ease-in-out;
    font-size: 1rem;
    &:checked{	  
      &:after{
        left: calc(100% - 2rem);
      }
    }
    &:after{
      position: absolute;
      content: "";
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 .25em rgba(0,0,0,.3);
      transform: scale(.7);
      left: 0;
      transition: all .2s ease-in-out;
    }
  }
}
</style>