<template>
  <router-link v-if="isRouting" :to="Link" class="button-rounded d-flex align-items-center justify-content-center"
    :class="[btnSize, color]" 
  >{{ Text }}</router-link>
  
  <button v-else class="button-rounded d-flex align-items-center justify-content-center"
    :class="[btnSize, color]" 
    :disabled="disabled"
  >{{ Text }}</button>
</template>

<script>
export default {
  name: "ButtonRounded",
  props: {
    Link: String,
    Text: String,
    btnSize: String,
    btnBg: String,
    isRouting: Boolean,
    color: String,
    disabled: Boolean,
  }
}
</script>
<style lang="scss">
.button-rounded{
  background: var(--gradientOrange);
  border-radius: 30px;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  min-width: 215px;
  height: 55px;
  padding: 0 1rem;
  font-weight: 500;
  font-size: 1.2rem;
  transition: var(--slow);
  &:hover{
    // box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
    // box-shadow: 0 27px 55px 0 rgba(0,0,0,.3), 0 17px 17px 0 rgba(0,0,0,.15);
    box-shadow: 0 10px 30px 5px rgba(0,0,0, .5);
    color: #fff;
  }
  &:disabled{
    background: var(--gray);
    cursor: not-allowed;
  }
  &.blue{
    background: var(--purpleDark);
  }
  &.large{}
  &.middle{
    width: 200px;
    height: 50px;
    font-size: 1rem;
  }
  &.small{}

  @media (max-width: 768px){
    min-width: 100px;
    height: 45px;
    &.middle{
      width: 145px;
      height: 35px;
    }
  }
}
</style>