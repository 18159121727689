<template>
  <form @submit.prevent="submitForm" autocomplete="off" class="d-flex h-100 w-100">
    <div class="sides left-side section-wrapper d-flex align-items-center justify-content-center">
      <div>
        <h3>Kampanya Oluştur</h3>

        <div class="form-group">
          <label for="campaignTitle">Kampanya Başlığı</label>
          <input 
            v-model="form.campaignTitle" 
            v-on:input="$v.form.campaignTitle.$touch"
            v-bind:class="{error: $v.form.campaignTitle.$error, valid: $v.form.campaignTitle.$dirty && !$v.form.campaignTitle.$invalid}"
            type="text" class="form-control" id="campaignTitle">
          <p class="form-warning" v-if="$v.form.campaignTitle.$invalid">Bu alan zorunludur.</p>
        </div>
        
        <div class="form-group">
          <label for="campaignMsg">Kampanya Başlığı</label>
          <textarea 
            v-model="form.campaignMsg" 
            v-on:input="$v.form.campaignMsg.$touch"
            v-bind:class="{error: $v.form.campaignMsg.$error, valid: $v.form.campaignMsg.$dirty && !$v.form.campaignMsg.$invalid}"
            type="text" class="form-control" id="campaignMsg"></textarea>
          <p class="form-warning" v-if="$v.form.campaignMsg.$invalid">Bu alan zorunludur.</p>
        </div>

      </div>
    </div>

    <div class="sides right-side section-wrapper d-flex align-items-center justify-content-center">
    
    </div>
  </form>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import {required} from 'vuelidate/lib/validators';

export default {
  name: "KampanyaOlustur",
  components: {
    ButtonRounded
  },
  data(){
    return{
      form: {
        campaignTitle: '',
        campaignMsg: '',
      },
    }
  },
  validations: {
    form:{
      campaignTitle: {
        required: required
      },
      campaignMsg: {
        required: required
      },
    }
  },
}
</script>