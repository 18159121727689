<template>
  <header class="d-flex align-items-center justify-content-between">
    <router-link to="/">
      <img class="non-sticky" src="/img/bilim-seferberligi-logo.png" alt="Bilim Seferberliği">
      <img class="sticky" src="/img/bilim-seferberligi-logo-dark.png" alt="Bilim Seferberliği">
    </router-link>
    
    <ButtonRounded class="float-right" 
      Text="Sen de Bağış Yap"
      btnSize="middle" 
      :isRouting="false"
      @click.native="showModal"
      />
  </header>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";

export default {
  name: "Header",
  components: {
    ButtonRounded
  },
  mounted(){
    this.getFooterSticky();
  },
  methods:{
    showModal(){
      this.$parent.showModal();
    },
    getFooterSticky(){
      $(window).on("scroll", function() {
          if($(window).scrollTop() > 100) {
            $("header").addClass("sticky");
          } else {
            $("header").removeClass("sticky");
          }
      });
    }
  }
}
</script>

<style lang="scss">
header{
  position: relative;
  width: 100vw;
  top: 0;
  padding: 1rem 2rem;
  margin-bottom: -90px;
  z-index: 2;
  transition: var(--fast);
  &.sticky{
    position: fixed;
    background: #fff;
    margin-bottom: 0;
    .non-sticky{
      display: none;
    }
    .sticky{
      display: inline-block;
    }
  }
  .non-sticky{
    display: inline-block;
  }
  .sticky{
    display: none;
  }

  @media(max-width: 768px){
    padding: .5rem 1rem;
    .sticky, .non-sticky{
      width: 130px;
    }
  }
}
</style>