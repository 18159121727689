<template>
  <router-link to="" class="user-card d-flex align-items-center">
    <img src="/img/oval.png">
    <div class="ml-2">
      <span>Kampanyayı Oluşturan</span>
      <p>Indira Shree</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "UserCard"
}
</script>

<style lang="scss">
.user-card{
  color: var(--whiteOld);
  line-height: 1.2;
  span{
    opacity: .5;
    font-size: 0.8rem;
  }
}
</style>