<template>
  <div class="birBagisYap padding-wrapper">
    <div class="d-flex">
      <div class="w-60">
        <h3>Kampanya Başlığı</h3>
        <p class="mt-2 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam facilisis ornare arcu eget iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <UserCard />
      </div>
      
      <div class="w-40 text-right">
        <div class="planned-donation">
          <div class="progress"><div class="progress-value"></div></div>

          <div class="d-flex align-items-center justify-content-between mt-3">
            <h3>2.000TL</h3>
            <div>
              <p>8.847TL</p>
              <span>Hedeflenen Bağış</span>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      
      <div class="bagis-card">
        <div class="price">20 TL</div>
        <div class="price-card-desc">
          <p>✋ Lorem ipsum</p>
          <span>Lorem ipsum dolor sit amet, consectetur</span>
        </div>
      </div>
      
      <div class="bagis-card">
        <div class="price">40 TL</div>
        <div class="price-card-desc">
          <p>☺️ Lorem ipsum</p>
          <span>Lorem ipsum dolor sit amet, consectetur</span>
        </div>
      </div>
      
      <div class="bagis-card">
        <div class="price">80 TL</div>
        <div class="price-card-desc">
          <p>😘 Lorem ipsum</p>
          <span>Lorem ipsum dolor sit amet, consectetur</span>
        </div>
      </div>
      
      <div class="bagis-card">
        <div class="price">0 TL</div>
        <div class="price-card-desc">
          <p>😘 Lorem ipsum</p>
          <span>Lorem ipsum dolor sit amet, consectetur</span>
        </div>
      </div>
      
      <div class="bagis-card">
        <div class="price">80 TL</div>
        <div class="price-card-desc">
          <p>😘 Lorem ipsum</p>
          <span>Lorem ipsum dolor sit amet, consectetur</span>
        </div>
      </div>
    </div> 

    <div class="d-flex justify-content-end">
      <ButtonRounded Link="/test" Text="Devam Et" :isRouting="true"/>
    </div>
  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import UserCard from "@/components/UserCard";

export default {
  name: "KampanyaBagis",
  components:{
    ButtonRounded, UserCard
  }
}
</script>

<style lang="scss">
.planned-donation{
  border: 2px solid #4f45db;
  border-radius: 10px;
  padding: .5rem;
  h3{
    font-size: 2.3rem;
    font-weight: 600;
  }
  p{
    font-size: 1.2rem;
    font-weight: 600;
  }
}
.progress {
  background: #fff;
  justify-content: flex-start;
  border-radius: 4px;
  align-items: center;
  position: relative;
  padding: 0;
  display: flex;
  height: 50px;
  width: 500px;
  .progress-value {
    animation: load 3s normal forwards;
    box-shadow: 0 10px 40px -10px var(--orange);
    background: var(--orange);
    height: 100%;
    width: 0;
  }
}


@keyframes load {
  0% { width: 0; }
  100% { width: 68%; }
}
</style>