<template>
  <div class="kartSec padding-wrapper w-100">
    <div class="d-flex justify-content-between">
      <div>
        <h3>Hediye Kartı Gönder</h3>
      </div>
      <div class="d-flex h-100">
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>
    </div>
    
    <swiper class="swiper-kartSec" :options="swiperOptionKart">
      <swiper-slide>
        <div class="image-wrapper position-relative">
          <img class="frame-img" src="/img/img-1.png">
          <span class="kart-badge">50TL</span>
        </div>
        <div class="px-2">
          <h3>Lorem ipsum</h3>
          <p>Lorem ipsum dolor sit amet, consectetur</p>
        </div>
      </swiper-slide>
      
      <swiper-slide>
        <div class="image-wrapper position-relative">
          <img class="frame-img" src="/img/img-1.png">
          <span class="kart-badge">50TL</span>
        </div>
        <div class="px-2">
          <h3>Lorem ipsum</h3>
          <p>Lorem ipsum dolor sit amet, consectetur</p>
        </div>
      </swiper-slide>
      
      <swiper-slide>
        <div class="image-wrapper position-relative">
          <img class="frame-img" src="/img/img-1.png">
          <span class="kart-badge">50TL</span>
        </div>
        <div class="px-2">
          <h3>Lorem ipsum</h3>
          <p>Lorem ipsum dolor sit amet, consectetur</p>
        </div>
      </swiper-slide>
      
      <swiper-slide>
        <div class="image-wrapper position-relative">
          <img class="frame-img" src="/img/img-1.png">
          <span class="kart-badge">50TL</span>
        </div>
        <div class="px-2">
          <h3>Lorem ipsum</h3>
          <p>Lorem ipsum dolor sit amet, consectetur</p>
        </div>
      </swiper-slide>
      
      <swiper-slide>
        <div class="image-wrapper position-relative">
          <img class="frame-img" src="/img/img-1.png">
          <span class="kart-badge">50TL</span>
        </div>
        <div class="px-2">
          <h3>Lorem ipsum</h3>
          <p>Lorem ipsum dolor sit amet, consectetur</p>
        </div>
      </swiper-slide>
      
      <swiper-slide>
        <div class="image-wrapper position-relative">
          <img class="frame-img" src="/img/img-1.png">
          <span class="kart-badge">50TL</span>
        </div>
        <div class="px-2">
          <h3>Lorem ipsum</h3>
          <p>Lorem ipsum dolor sit amet, consectetur</p>
        </div>
      </swiper-slide>
      
      <swiper-slide>
        <div class="image-wrapper position-relative">
          <img class="frame-img" src="/img/img-1.png">
          <span class="kart-badge">50TL</span>
        </div>
        <div class="px-2">
          <h3>Lorem ipsum</h3>
          <p>Lorem ipsum dolor sit amet, consectetur</p>
        </div>
      </swiper-slide>
    </swiper>

    <div class="d-flex justify-content-end">
      <ButtonRounded Link="/test" Text="Devam Et" :isRouting="true"/>
    </div>
  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
export default {
  name: "KartSec",
  components: {
    ButtonRounded
  },
  data() {
    return {
      swiperOptionKart: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loop: false,
        slidesPerView: 5,
        spaceBetween: 20,
      },
    };
  },
}
</script>

<style lang="scss">
.kartSec{
  .swiper-button-prev,
  .swiper-button-next{
    position: relative;
    background: #fff;
    border-radius: 50%;
    width: 2.9rem;
    height: 2.9rem;
    left: unset;
    right: unset;
    margin: 0 .5rem;
    &:after{
      font-size: 1.4rem;
      font-weight: 700;
    }
  }


  .swiper-container.swiper-kartSec.swiper-container-horizontal{
    padding: 3rem 0;
    .swiper-slide{
      padding: 0;
      justify-content: center;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &:hover{
        .image-wrapper{
          box-shadow: 0 10px 30px 5px rgba(0,0,0, .5);
        }      
      }
    }
  }

  h3{
    font-size: 1.4rem;
    font-weight: 600;
    margin: 1.5rem 0 0.5rem 0;
  }

}
</style>