<template>
  <div class="birBagisYap padding-wrapper">
    <div class="d-flex flex-column flex-md-row align-items-end">
      <div class="heading">
        <h3>Sen de Bağış Yap</h3>
        <p class="mt-4">Bağış yapmak istediğin tutarı aşağıdan seçebilirsin. Dilersen bağış tutarını kendin belirleyebilir ya da istediğin sayıda Bilim Seti bağışlayabilirsin.</p>
      </div>
      
      <!-- <div class="w-50 text-right">
        <select v-model="selected" class="custom-selectbox" placeholder="Bağış Yapmak İstediğin Şehri Seç">
          <option disabled value="">Bağış Yapmak İstediğin Şehri Seç</option>
          <option>İstanbul</option>
          <option>Ankara</option>
          <option>İzmir</option>
        </select>
      </div> -->

      <div class="bottom-information text-left text-md-right">
        <h3>Bağışını 
          <Toggle  :labels="toggleLabels" bgColor="orange" @toggle-checkbox="checkboxValue" />
          olarak yapabilirsin.
        </h3>
      </div>
    </div>

    <div class="d-flex flex-wrap flex-xl-nowrap justify-content-center">

      <div v-for="item in donationData" :key="item.id"
      :id="item.id"
      class="bagis-card" @click.self="selectDonation($event, 'bagis', 'auto', item.id, item.price, 1)">
        <img src="/svg/check.svg" class="check-icon">
        <div class="price">{{ item.full_price }}</div>
        <div class="price-card-desc">
          <p>{{item.title}}</p>
          <span>{{item.description}}</span>
        </div>
      </div>    

      <div class="bagis-card text-donation" @click="selectDonation($event, 'bagis', 'manuel', 0, 0, 1)">
        <img src="/svg/check.svg" class="check-icon">
        <the-mask
            mask="###########"
            placeholder="0 TL"
            v-model="donationValue" 
            v-on:input='donationValue >= 1 ? bagisCardIsSelected = true : bagisCardIsSelected = false'
            type="tel" class="price" />
        <div class="price-card-desc">
          <p>Kendin Belirle</p>
          <span>Bağış yapmak istediğin tutarı kendin belirle.</span>
        </div>
      </div>


      <div class="bagis-card" @click="selectDonation($event, 'bilimseti', 'auto', 0, 2000, formValue.amount)">
        <img src="/svg/check.svg" class="check-icon">
        <div class="count">
          <span @click=" formValue.amount > 1 ? formValue.amount-- : '' ">-</span>
          <input type="text" class="price" :placeholder="formValue.amount" readonly>
          <span @click="formValue.amount++">+</span>
        </div>
        <div class="price-card-desc">
          <p>Bilim Seti</p>
          <span>Bir bilim setinin bedeli 
            <strong>2.000TL</strong>'dir, her setten 
            <strong>80 çocuk</strong> faydalanır.</span>
          
        </div>
      </div>
      
    </div>

    <div class="d-flex align-items-center flex-column flex-md-row justify-content-end bottom-info">
      <!-- <div class="d-flex align-items-center">
        <span class="svg-icon icons-information"></span>
        <p>Düzenli ödeme talimatı verdiğiniz için bağışınız her ayın **/**/**** tarihinde belirlemiş olduğunuz kredi kartından otomatik çekilecektir.</p>
      </div> -->
      
      <ButtonRounded Text="Devam Et" :isRouting="false" :disabled="!bagisCardIsSelected" @click.native="formValidation"/>
    </div>
  </div>
</template>
<script>
import Toggle from "@/components/Toggle";
import ButtonRounded from "@/components/ButtonRounded";

export default {
  name: "BirBagisYap",
  components:{
    Toggle, ButtonRounded
  },
  props: [ 'donationData' ],
  data(){
    return{
      toggleLabels: ["tek sefer", "düzenli"],
      selected: '',
      bagisCardIsSelected: false,
      isDisabled: true,
      donationValue: '',
      formValue: {
        donation_type: '',
        selected_type: '',
        donation_status: 'teksefer',
        donation_id: 0,
        price: 0, 
        amount: 1,
      },
    }
  },
  methods:{
    checkboxValue(e){
      e == true ? this.formValue.donation_status = "duzenli" : this.formValue.donation_status = "teksefer";
      this.$store.commit('_donation_status', this.formValue.donation_status);
     // console.log(this.formValue.donation_status);
    },
    selectDonation(e, type, selected_type, donation_id, price, amount){ 
      $(".bagis-card").not(e.target).removeClass("active");

      if($(e.target).prop('nodeName') == "SPAN" || $(e.target).prop('nodeName') == "INPUT"){
        $(e.target).closest(".bagis-card").addClass("active");
      }else{
        e.target.classList.add("active");
      }

      this.bagisCardIsSelected = true;
      
      if( $(".text-donation").hasClass("active") && $(".text-donation input").val() == "" ){
        this.bagisCardIsSelected = false;
      }
      this.formValue.donation_type = type;
      this.formValue.selected_type = selected_type;
      this.formValue.donation_id = donation_id;
      this.formValue.price = price;
      this.formValue.amount = amount;

      this.$store.commit('_donation_status', this.formValue.donation_status);

      // Slide End
      var elem = document.querySelector(".modal-wrapper");
      elem.scrollIntoView({behavior: "smooth", block: "end"});
    },
    formValidation(){      
      if(this.formValue.selected_type == "manuel" && this.formValue.donation_type == "bagis"){
        this.formValue.price = this.donationValue;
      }

      this.$store.commit('_formValue', this.formValue);
      this.$store.commit('_selectedDonatinPrice', this.formValue.price);

      this.bagisCardIsSelected ? this.slideToPage(2) : '';
      var elem = document.querySelector(".modal-wrapper");
      elem.scrollIntoView({behavior: "smooth"});
    },
    slideToPage (event) {
      this.$emit('clicked');
    
      gtag('event', 'Bağış Seçimi', {
        'event_category': 'Bağış',
        'event_label': 'Bağış Seçimi',
        'value': ''
      });
    },
  }
}
</script>

<style lang="scss">
.birBagisYap{
    display: flex;
    flex-direction: column;
    justify-content: center;
  .heading{
    width: 50%;
  }
  .bottom-information{
    width: 50%;
  }
  .bagis-card{
    background: #fff;
    color: var(--purpleDark);
    text-align: center;
    transition: var(--slow);
    margin: 2rem 1rem 1.5rem 0;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    &:last-child{
      margin-right: 0;
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      opacity: 0;
      z-index: -1;
      transition: var(--slow);
      background: var(--gradientOrange);
    }
    &:hover, &.active{
      &:before{
        opacity: 1;
      }
      box-shadow: 0 10px 30px 5px rgba(0,0,0, .5);
      // box-shadow: 0 27px 55px 0 rgba(0,0,0,.3), 0 17px 17px 0 rgba(0,0,0,.15);

      .price{
        border-bottom: 1px solid rgba(240,239,250, .1);
      }
    }
    &.active{
      .check-icon{
        display: block;
      }
    }
    .price{
      font-size: 2.8rem;
      font-weight: bold;
      border-bottom: 1px solid var(--borderBlue);
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      pointer-events: none;
    }
    input.price{
      color: var(--purpleDark);
      border: 1px solid var(--borderBlue);
      border-radius: 5px;
      height: 90px;
      max-width: 200px;
      padding-bottom: 0;
      text-align: center;
      pointer-events: auto;
    }
    .price-card-desc{
      pointer-events: none;
      p{
        pointer-events: none;
        font-weight: bold;
        font-size: 1.4rem;
        margin-bottom: .5rem;
      }
    }
    .check-icon{
      display: none;
      position: absolute;
      top: 0;
      right: 0;
    }
    .count{
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        background: var(--purpleDark);
        color: #fff;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: inline-block;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 -12px;
        position: relative;
        top: -8px;
        font-size: 1.1rem;
        font-weight: 600;
        pointer-events: auto;
      }
    }
  }
  .svg-icon{
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
  
  @media (max-width: 1550px){
    .bagis-card{
      padding: 2rem 1.5rem;
    }
  }

  @media (max-width: 1366px){
    .bagis-card input.price{
      max-width: 130px;
    }
  }
  
  
  @media (max-width: 1024px){
    .bagis-card{
      margin: 0 2px .5rem 0;
      padding: 1.5rem 1rem;
      width: 49%;
      &:last-child{
        margin: 0 .5rem .5rem 0;
        width: 99%;
        input.price{
          width: 50%;
          max-width: unset;
        }
      }
    }
  }
  
  @media (max-width: 768px){
    .heading{
      width: 100%;
      margin-bottom: 1rem;
    }
    .bottom-information{
      width: 100%;
      margin-bottom: 1rem;
    }
    .bagis-card {
      width: 49%;
      input.price{
        max-width: 130px;
        height: 60px;
      }

      .price-card-desc{
        p{
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
        span{
          font-size: .9rem;
        }
      } 

      .count span{
        line-height: 1.9;
        width: 39px;
        height: 35px;
        font-size: 1.4rem;
      }
    }

    .bottom-info{
      padding: 1rem 0 4rem 0;
      .svg-icon{
        width: 5rem;
      }

      .bottom-information{
        padding-bottom: 2rem;
        h3{
          margin-bottom: 1rem;
        }
      }

      .button-rounded{
        width: 100%;
      }
    }

  }
}
</style>