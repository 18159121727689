<template>
  <div class="odeme-wrapper d-flex">
    <modalPayment :data="modalData" :modalMsg="modalMsg" :success="modalSuccess" ref="modalPayment"></modalPayment>

    <div class="sides left-side section-wrapper d-none d-md-flex align-items-center justify-content-center">
      <div class="sides-container d-flex flex-column justify-content-around">
        <h3>Ödeme</h3>
        <img src="/svg/odeme.svg"/>
        <div>
          <!-- <h2>80,00 TL</h2> --> 
          
          <h2>{{this.$store.state.userInfo.total_price}}</h2>
          <p>
            Yapacağınız bağış ile Türkiye’nin dört bir köşesindeki çocuklara son teknoloji bilim setleri gönderilmesine
            katkı sağladığınız için teşekkür ederiz.
          </p>
        </div>
      </div>
    </div>

    <div class="sides right-side section-wrapper d-flex flex-column align-items-center justify-content-center">

      <div class="d-block d-md-none mb-5">
        <h3>Ödeme</h3>
        <div>
          <!-- <h2>80,00 TL</h2> -->
          <h2>{{this.$store.state.userInfo.total_price}}</h2>
          <p>
            Yapacağınız bağış ile Türkiye’nin dört bir köşesindeki çocuklara son teknoloji bilim setleri gönderilmesine
            katkı sağladığınız için teşekkür ederiz.
          </p>
        </div>
      </div>

      <form @submit.prevent="submitForm" autocomplete="off" class="w-100">
        <loading :active.sync="isLoading" 
        background-color="#332abb"
        :opacity= 0.99
        color="#fff"
        :is-full-page="fullPage"></loading>
        <div class="form-group">
          <label for="cardName">Kart Üzerindeki İsim</label>
          <input
              name="ccname" id="frmNameCC" autocomplete="cc-name"
              v-model="form.cardName"
              v-on:input="removeSpace"
              v-bind:class="{error: $v.form.cardName.$error, valid: $v.form.cardName.$dirty && !$v.form.cardName.$invalid}"
              type="text" class="form-control credit-card-name">
          <p class="form-warning" v-if="!$v.form.cardName.alpha">Ad Soyad şeklinde giriniz.</p>
          <p class="form-warning" v-if="!$v.form.cardName.nameSurnameLength">En az 2 karakter girmelisiniz</p>
          <p class="form-warning" v-if="!$v.form.cardName.required">Bu alan zorunludur.</p>
          <p class="form-warning" v-if="!$v.form.cardName.minLength">En az 2 karakter girmelisiniz.</p>
        </div>

        <div class="form-group">
          <label for="cardNo">Kart Numarası</label>
          <the-mask
              name="cardnumber" id="frmCCNum" autocomplete="cc-number"
              mask="#### #### #### ####"
              placeholder="---- ---- ---- -----"
              v-model="form.cardNo"
              v-bind:class="{error: $v.form.cardNo.$error, valid: $v.form.cardNo.$dirty && !$v.form.cardNo.$invalid}"
              type="tel" class="form-control"/>
          <p class="form-warning" v-if="!$v.form.cardNo.required">Bu alan zorunludur.</p>
          <p class="form-warning" v-if="!$v.form.cardNo.minLength">Yanlış kart formatı.</p>
        </div>

        <div class="row ">
          <div class="col-7 d-flex justify-content-between">
            <div class="form-group w-100 mr-4">
              <label for="cardMonth">Ay</label>
              <select class="custom-selectbox"
                      v-model="form.selectedMonth"
                      v-on:input="form.selectedMonth = form.selectedMonth"
                      v-bind:class="{error: $v.form.selectedMonth.$error, valid: $v.form.selectedMonth.$dirty && !$v.form.selectedMonth.$invalid}"
              >
                <option value="" disabled selected>Ay</option>
                <option v-bind:value="n < 10 ? '0' + n : n" v-for="n in 12" v-bind:disabled="n < minCardMonth"
                        v-bind:key="n">
                  {{ n < 10 ? '0' + n : n }}
                </option>
              </select>
            </div>

            <div class="form-group w-100">
              <label for="cardYear">Yıl</label>
              <select class="custom-selectbox"
                      v-model="form.selectedYear"
                      v-on:input="form.selectedYear = form.selectedYear"
                      v-bind:class="{error: $v.form.selectedYear.$error, valid: $v.form.selectedYear.$dirty && !$v.form.selectedYear.$invalid}"
              >
                <option value="" disabled selected>Yıl</option>
                <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">
                  {{ $index + minCardYear }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-5">
            <div class="form-group">
              <label for="cardCvc">CVC</label>
              <the-mask class="form-control"
                        type="tel" 
                        name="cvc" id="frmCCCVC" autocomplete="cc-csc"
                        mask="####"
                        v-model="form.cardCvc"
                        v-bind:class="{error: $v.form.cardCvc.$error, valid: $v.form.cardCvc.$dirty && !$v.form.cardCvc.$invalid}"
              />
            </div>
          </div>
        </div>

        <!-- <label class="custom-checkbox"> <span>Kredi Kartımdan </span> çekilmesini Kabul ediyorum.
          <input type="checkbox"
            v-model="form.terms_accepted"
            v-bind:class="{error: $v.form.terms_accepted.$error, valid: $v.form.terms_accepted.$dirty && !$v.form.terms_accepted.$invalid}"
          >
          <span class="checkmark"></span>
        </label> -->
        <ButtonRounded class="float-right"
                       Text="Ödemeyi Tamamla"
                       :isRouting="false"
                       :disabled="disabled"
        />
      </form>
    </div>
  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import ModalPayment from "@/components/ModalPayment";
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import {integer, required, maxLength, minLength, helpers} from 'vuelidate/lib/validators';

const nameSurnameLength = (value) => {
  let isValid = true;
  value.split(" ").forEach(e => {
    if(!/^\s*$/.test(e)){
      if (e.length < 2) {
        isValid = false;
      }
    }
  });
  return isValid;
}

export default {
  name: "Odeme",
  components: {
    ButtonRounded, ModalPayment, Loading
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      price: '',
      paymentResponse: '',
      modalSuccess: false,
      modalData: '',
      modalMsg: '',
      minCardYear: new Date().getFullYear(),
      disabled: false,
      form: {
        cardName: '',
        cardNo: '',
        selectedMonth: '',
        selectedYear: '',
        cardCvc: '',
        terms_accepted: false,
      }
    }
  },
  computed: {
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    }
  },
  validations: {
    form: {
      cardName: {
        required,
        nameSurnameLength,
        alpha: helpers.regex('alpha', /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/i),
        minLength: minLength(2),
      },
      cardNo: {
        required,
        integer: integer,
        minLength: minLength(16),
      },
      cardCvc: {
        required,
        maxLength: minLength(3),
        maxLength: maxLength(4),
      },
      // terms_accepted: {
      //   checked: value => value === true
      // },
      selectedMonth: {
        required
      },
      selectedYear: {
        required
      }
    }
  },
  methods: {
    doAjax() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      },1500)
    },
    showModalPayment() {
      this.$refs.modalPayment.openModal();
    },
    closeModalPayment() {
      this.$refs.modalPayment.closeModal();
    },
    removeSpace: function (event) {
      this.form.cardName = this.form.cardName.replace(/\s\s+/g, ' ');
    },
    payment(data) {
      let params = {}

      params["conversation_id"] = this.$store.state.conversation_id;
      params["card_holder_name"] = data.cardName.$model;
      params["card_number"] = data.cardNo.$model;
      params["expire_month"] = data.selectedMonth.$model;
      params["expire_year"] = data.selectedYear.$model;
      params["cvc"] = data.cardCvc.$model;

      this.isLoading = true;
      this.disabled = true;

      if(this.$store.state.donation_status == "teksefer" ){
        // Tek Sefer
        this.$api.paymentTeksefer(params).then(response => {
          // console.log(response);
          this.showModalPayment();
  
          this.modalSuccess = response.success;
  
          if (response.success) {
            var elem = document.querySelector(".modal-wrapper");
            elem.scrollIntoView({behavior: "smooth"});
  
            this.disabled = false;
  
            this.modalData = response.redirect3D;
            this.isLoading = false;
            this.checkStatus();
     
                gtag('event', 'Bağış Ödeme Adımı (Teksefer)', {
                  'event_category': 'Bağış',
                  'event_label': 'Bağış Ödeme Adımı (Teksefer)',
                  'value': ''
                });
          } else {
            this.modalMsg = response;
            this.disabled = false;
            this.isLoading = false;
          }
        })

      }else if(this.$store.state.donation_status == "duzenli" ){
        // Duzenli
        this.$api.paymentDuzenli(params).then(response => {
          // console.log(response);
          this.showModalPayment();
  
          this.modalSuccess = response.success;
          if (response.success) {
            var elem = document.querySelector(".modal-wrapper");
            elem.scrollIntoView({behavior: "smooth"});
            this.isLoading = false;
            this.closeModalPayment();
            this.$emit('clicked', '1');
            this.disabled = false;
            this.modalMsg = response;
               gtag('event', 'Bağış Ödeme Adımı (Düzenli)', {
                  'event_category': 'Bağış',
                  'event_label': 'Bağış Ödeme Adımı (Düzenli)',
                  'value': ''
                });
            // this.modalData = response.redirect3D;
            // this.checkStatus();
          } else {
            this.modalMsg = response;

            this.disabled = false;
            this.isLoading = false;
          }
        })
      }

    },
    submitForm() {
      this.$v.$touch();
      // console.log(this.$v);
      if (!this.$v.form.$invalid) {
        // console.log("valid");
        // this.$emit('clicked', '1');
        this.payment(this.$v.form);
      } else {
        // console.log("not valid");
      }
    },
    checkStatus() {
      // console.log("pending...");
      let params = {}
      params["conversation_id"] = this.$store.state.conversation_id;

      let interval = setInterval(() => {
        this.$api.checkStatus(params).then(response => {
          // console.log("payment status", response);

          this.modalSuccess = true;

          if (response.status == "success") {
            this.isLoading = false;
            clearInterval(interval);
            this.closeModalPayment();
            this.$emit('clicked', '1');
               
                gtag('event', 'Bağış Ödeme Final', {
                            'event_category': 'Bağış',
                            'event_label': 'Başarılı Ödeme',
                            'value': ''
                          });
              gtag('event', 'purchase', {
            "transaction_id": this.$store.state.conversation_id,
            "affiliation": "23 Nisan Kampanya Bağışı",
            "value": this.$store.state.userInfo.total_price,
            "currency": "TRY",
            "tax": 0,
            "shipping": 0,
            "items": [
              {
                "id": this.$store.state.conversation_id,
                "name": "23 Nisan Kampanya Bağışı",
                "list_name": "Bağış Tipi",
                "brand": this.$store.state.formValue.donation_status,
                "category": "Bağış",
                "variant": '',
                "list_position": 1,
                "quantity": 1,
                "price": this.$store.state.userInfo.total_price
              },
            ]
          });
          } else if (response.status == "error") {
          /*  $(".payment-modal iframe").remove();*/
            clearInterval(interval);
            this.modalSuccess = false;
            this.modalMsg = response;
          }
        })
      }, 1000);


    },
  }

};
</script>

<style lang="scss">
.credit-card-name {
  text-transform: uppercase;
}

.odeme-wrapper {
  height: 100%;
}

@media (max-wdith: 768px) {
  .odeme-wrapper {
    height: auto;
  }
}

.sides {
  height: 100%;
  width: 50vw;

  .sides-container {
    height: 100%;
    // width: 450px;
    img {
      margin: -30px 0;
    }

    h2 {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }
}

.left-side {
  background: var(--purpleMid);
}

.right-side {
  background: var(--blueOcean);
}
</style>
