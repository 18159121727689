<template>
<transition name="fade">
  <div class="modal main-modal" v-if="show">
    <loading :active.sync="isLoading" 
    background-color="#332abb"
    :opacity= 0.99
    color="#fff"
    :is-full-page="fullPage"></loading>

    <div class="modal-container">
      <div class="modal-wrapper">
        <div class="swiper-carousel swiper-container swiper swiper-container-horizontal">
          <div class="swiper-wrapper">
            <!-- <swiper-slide><BagisSec /></swiper-slide> -->
            <div class="swiper-slide"><BirBagisYap  @clicked="slideToPage" :donationData="donationData"/></div>
            <div class="swiper-slide"><Bilgiler @clicked="slideToPage" :formValue="formValue"/></div>
            <div class="swiper-slide"><Odeme @clicked="slideToPage"/></div>
            <div class="swiper-slide"><FormSuccess  @clicked="closeModal;destroyCarousel"/></div>
            <!-- <swiper-slide><KartSec /></swiper-slide>
            <swiper-slide><KampanyaBagis /></swiper-slide>
            <swiper-slide><KartBilgileri /></swiper-slide>
            <swiper-slide><KampanyaOlustur /></swiper-slide> -->
          </div>
          
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
        <FrameFooter :backNav="backNav" @clicked="prevSlide; destroyModal"/>
      </div>

    </div>
  </div>
</transition>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { Swiper, Scrollbar, EffectCoverflow, SwiperClass, Pagination, Navigation, Mousewheel, Autoplay, EffectFade } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar, EffectCoverflow, Mousewheel, Autoplay, EffectFade]);
import 'swiper/swiper-bundle.css';

import FrameFooter from "@/components/FrameFooter";
import BagisSec from "@/components/BagisSec";
import BirBagisYap from "@/components/BirBagisYap";
import KartSec from "@/components/KartSec";
import KampanyaBagis from "@/components/KampanyaBagis";
import Odeme from "@/components/Odeme";
import KartBilgileri from "@/components/KartBilgileri";
import Bilgiler from "@/components/Bilgiler";
import KampanyaOlustur from "@/components/KampanyaOlustur";
import FormSuccess from "@/components/FormSuccess";

export default {
  name: "Carousel",
  components: {
    BagisSec, BirBagisYap, KartSec, KampanyaBagis, Odeme, FrameFooter, KartBilgileri, Bilgiler, KampanyaOlustur,
    FormSuccess, Loading
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
      donationData: {},
      formValue: {},
      backNav: ["Ana Sayfa", "/"],
      formData: '',
      show: false,
      swiperModal: '',
    };
  },
  mounted() {
    this.getDonationList();
  },
  methods:{
    getSwiper(){
      setTimeout(() =>{
        this.swiperModal = new Swiper('.swiper-carousel', {
          pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
          },
          loop: false,
          slideToClickedSlide:true,
          paginationClickable: false,
          mousewheelControl: 1,
          parallax: false,
          speed: 0,
          slidesPerView: 1,
          effect: "fade",
          fadeEffect: { crossFade: true },
          allowTouchMove: false,
          grabCursor: false,
          simulateTouch: false,
        });
      }, 100);
    },
    getDonationList(){
      this.$api.donationList().then(response =>{
        this.donationData = response;
        this.isLoading = false;
      });
    },
    footerLink(data){
      this.backNav = data;
    },
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
    slideToPage (value) {
      this.swiperModal.slideNext();
      if (this.swiperModal.activeIndex == 0){
        this.backNav = ["Ana Sayfa", ""]
      } else if (this.swiperModal.activeIndex == 1){
        this.backNav = ["Bir Bağış Yap", ""]
      }
      else if (this.swiperModal.activeIndex == 2){
        this.backNav = ["Bilgileriniz", ""];
        $("button").blur();
      }
    },
    prevSlide(){
      if(this.swiperModal.activeIndex > 0 ){
        this.swiperModal.slidePrev();
      }else{
        this.closeModal();
      }

      if (this.swiperModal.activeIndex == 0){
        this.backNav = ["Ana Sayfa", ""]
      } else if (this.swiperModal.activeIndex == 1){
        this.backNav = ["Bir Bağış Yap", ""]
      }
      else if (this.swiperModal.activeIndex == 2){
        this.backNav = ["Bilgileriniz", ""];
        $("button").blur();
      }
    },
    destroyCarousel(){
      this.swiperModal = '';
    },
  }
};
</script>