<template>
<div class="bagisSec d-flex align-items-center">
  <div class="padding-wrapper d-flex">
    <router-link to="/carousel" class="bagis-card d-flex flex-column align-items-center justify-content-between text-center">
      <div class="img-wrapper d-flex aling-items-center justify-content-center">
        <img src="/svg/bagis-yap.svg">
      </div>
      
      <div>
        <h3>Bir Bağış Yap</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam facilisis ornare arcu eget iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
    </router-link>

    <router-link to="/carousel" class="bagis-card d-flex flex-column align-items-center justify-content-between text-center">
      <div class="img-wrapper d-flex aling-items-center justify-content-center">
        <img src="/svg/kampanya-olustur.svg">
      </div>
      
      <div>
        <h3>Kampanya Oluştur!</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam facilisis ornare arcu eget iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
    </router-link>

    <router-link to="/carousel" class="bagis-card d-flex flex-column align-items-center justify-content-between text-center">
      <div class="img-wrapper d-flex aling-items-center justify-content-center">
        <img src="/svg/hediye-karti-gonder.svg">
      </div>
      
      <div>
        <h3>Hediye Kartı Gönder!</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam facilisis ornare arcu eget iaculis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
    </router-link>
  
  </div>
</div>  
</template>
<script>
export default {
  name: "BagisSec",
}
</script>

<style lang="scss">
.bagisSec{
  // padding: 0 10vw;
  height: calc(100vh - 150px);
  width: 100vw;
  background: var(--blueOcean);
  color: #fff;
}
</style>