<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script defer src="https://use.fontawesome.com/releases/v5.0.6/js/all.js"></script>
<script>


export default {
  name: "app",
}
</script>
<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
</style>
<!-- <style src="@/styles/main.scss" lang="scss"></style> -->